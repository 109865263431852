import React from "react";
import { graphql, Link } from "gatsby";

import { MetaProps } from "~components/templates/seo";
import * as styles from "./index.module.scss";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { KenroVoiceList } from "~components/kenro/voice-list";

type Props = {
  data: GatsbyKenroCasePageQuery;
};

const Component: React.FC<Props & { className?: string }> = ({ ...props }) => {
  const { data } = props;
  const meta: MetaProps = {
    title: "導入事例 | KENRO",
    image: `https://flatt.tech${data?.ogp?.publicURL || ""}`,
    description:
      "KENROを導入して、エンジニアのセキュリティ能力向上に成功した企業の事例を紹介しています",
    isSeparateSite: true,
    useTypeSquareFonts: true,
  };

  return (
    <Kenro2PaneLayout meta={meta}>
      <div className={styles.Summary}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.Summary_Container}>
            <h2 className={styles.Summary_Heading}>導入事例</h2>
            <div className={styles.Summary_Description}>
              KENROの導入により、エンジニアのセキュリティ能力向上に成功した企業の事例を紹介しています。
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Cases}>
        <div className={styles.Cases_List}>
          <KenroVoiceList />
        </div>
      </div>
    </Kenro2PaneLayout>
  );
};

const Container: React.FC<Props> = (props) => {
  return (
    <>
      <Component {...props} />
    </>
  );
};

export const query = graphql`
  query KenroCasePage {
    logo_color: file(relativePath: { eq: "kenro/logo_color.svg" }) {
      publicURL
    }
  }
`;

export default Container;
